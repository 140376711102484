
import Vue, { PropType } from "vue"
import { GateReportEvent } from "@evercam/shared/types"
import { AiApi } from "@evercam/shared/api/aiApi"

export default Vue.extend({
  name: "TimelinePlayerGateReportTooltip",
  props: {
    event: {
      type: Object as PropType<GateReportEvent>,
      required: true,
    },
    showEventType: {
      type: Boolean,
      default: true,
    },
    showCameraName: {
      type: Boolean,
      default: true,
    },
    showCameraExid: {
      type: Boolean,
      default: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isThumbnailMissing: false,
    }
  },
  computed: {
    formattedTimestamp(): string {
      return this.$moment
        .tz(this.event.eventTime, this.timezone)
        .format("YYYY-MM-DDTHH:mm:ss")
    },
    thumbnailUrl(): string {
      return AiApi.gateReport.getEventThumbnail({
        cameraExid: this.event.cameraExid,
        eventType: this.event.eventType,
        eventTime: this.event.eventTime,
        token: this.token,
      })
    },
  },
})
