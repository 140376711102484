
import { Media, MediaType } from "@evercam/shared/types"
import Vue, { PropType } from "vue"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "TimelinePlayerMediaHubMilestone",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    item: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
    width: {
      type: Number,
      default: 120,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      aspectRatio: 16 / 9,
    }
  },
  computed: {
    calculatedWidth(): number {
      if (this.maxHeight) {
        return Math.min(this.width, this.maxHeight * this.aspectRatio)
      }

      return this.width
    },
    maxHeight(): number {
      return this.width / (16 / 9)
    },
    tooltipWidthStyle(): Record<string, any> {
      return {
        width: `${this.calculatedWidth}px`,
        minWidth: `${this.calculatedWidth}px`,
      }
    },
    tooltipHeightStyle(): Record<string, any> {
      if (!this.large || this.maxHeight) {
        return {
          height: `${this.calculatedWidth / this.aspectRatio}px`,
          minHeight: `${this.calculatedWidth / this.aspectRatio}px`,
        }
      }

      return {
        height: `${this.calculatedWidth / 1.42}px`,
        minHeight: `${this.calculatedWidth / 1.42}px`,
      }
    },
    icon(): string {
      let mediaUrl = this.item.mediaUrl || ""
      if (this.item.type === MediaType.Compare) {
        return this.$device.isIos
          ? "fas fa-compress"
          : "fas fa-compress-arrows-alt"
      } else if (this.item.type === MediaType.Clip) {
        return "fas fa-cloud"
      } else if (this.item.type === MediaType.LocalClip) {
        return "fas fa-video"
      } else if (this.item.type === MediaType.XRay) {
        return "fas fa-x-ray"
      } else if (this.item.type === MediaType.ExternalUrl) {
        if (mediaUrl.includes("youtube.com")) {
          return "fab fa-youtube"
        } else if (mediaUrl.includes("vimeo.com")) {
          return "fab fa-vimeo"
        } else {
          return "fas fa-link"
        }
      } else if (this.item.type === MediaType.EditedImage) {
        return "fas fa-images"
      } else if (this.item.type === MediaType.File) {
        return "fas fa-upload"
      } else {
        return this.$device.isIos ? "fas fa-clock" : "fas fa-history"
      }
    },
  },
  methods: {
    calculateAspectRatio() {
      const image = this.$refs.vImg?.image as HTMLImageElement
      if (!image) {
        return
      }

      this.aspectRatio = image.naturalWidth / image.naturalHeight
    },
  },
})
