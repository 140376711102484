
import Vue, { PropType } from "vue"

export default Vue.extend({
  name: "TimelinePlayerImageTooltip",
  props: {
    item: {
      type: Object as PropType<{
        label: string
        src: string
        timestamp: string
      }>,
      required: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    large: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelHeight: {
      type: Number,
      default: 48,
    },
  },

  data() {
    return {
      aspectRatio: 16 / 9,
    }
  },
  computed: {
    calculatedWidth(): number {
      if (this.maxHeight) {
        return Math.min(this.width, this.maxHeight * this.labelHeight)
      }

      return this.width
    },
    maxHeight(): number {
      return this.width / this.aspectRatio
    },
    tooltipWidthStyle(): Record<string, any> {
      return {
        width: `${this.calculatedWidth}px`,
        minWidth: `${this.calculatedWidth}px`,
      }
    },
    tooltipHeightStyle(): Record<string, any> {
      if (!this.large || this.maxHeight) {
        return {
          height: `${
            this.calculatedWidth / this.aspectRatio + this.labelHeight
          }px`,
          minHeight: `${
            this.calculatedWidth / this.aspectRatio + this.labelHeight
          }px`,
        }
      }

      return {
        height: `${this.calculatedWidth / 1.42}px`,
        minHeight: `${this.calculatedWidth / 1.42}px`,
      }
    },
  },
  methods: {
    calculateAspectRatio() {
      const image = this.$refs.vImg?.image as HTMLImageElement
      if (!image) {
        return
      }

      this.aspectRatio = image.naturalWidth / image.naturalHeight
    },
  },
})
